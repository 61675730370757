import React from 'react'
import "./Reviews.css"
import { Icon } from '@iconify/react';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import Carousel from 'react-bootstrap/Carousel';

import { Autoplay, Pagination, Navigation } from "swiper";
const Reviews = () => {

    return (
        <div className='reviews' style={{ "width": "100%", "height": "100%" }}>
            <Carousel
                controls="false"


            >
                <Carousel.Item>
                    <div id="content">
                        <div class="testimonial">
                            <blockquote>
                                Very much patience thinking out of the box one of my better experiences of Fiverr. Delivers quality and really listens to your input and combines it to the result you both agree. Next job I will chose Oshri again                            </blockquote>
                            <div></div>
                            <p>
                                dezotte, Belgium

                            </p>
                        </div>
                    </div>

                </Carousel.Item>
                <Carousel.Item>
                    <div id="content">
                        <div class="testimonial">
                            <blockquote>
                                he did amazing job great guy to work with.
                            </blockquote>
                            <div></div>
                            <p>
                                shahzaibkamal, Pakistan
                            </p>
                        </div>
                    </div>


                </Carousel.Item>
                <Carousel.Item>
                    <div id="content">
                        <div class="testimonial">
                            <blockquote>
                                Great communication with the seller. The seller is very creative, and shared ideas which we added on the final web site. Thank you                            </blockquote>
                            <div></div>
                            <p>
                                jkeret,USA
                            </p>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div id="content">
                        <div class="testimonial">
                            <blockquote>
                                Great job and support!

                            </blockquote>
                            <div></div>
                            <p>
                                accollege,Australia
                            </p>
                        </div>
                    </div>
                </Carousel.Item>
                {/* <Carousel.Item>
                    <div id="content">
                        <div class="testimonial">
                            <blockquote>
                                Outstanding communication, exceptional professionalism and final product. Highly Recommended                                           </blockquote>
                            <div></div>
                            <p>
                                accollege,Australia
                            </p>
                        </div>
                    </div>
                </Carousel.Item> */}
                <Carousel.Item>
                    <div id="content">
                        <div class="testimonial">
                            <blockquote>
                                I’m an online marketer for a long time, working on different projects and different clients. Osheri delivered a job that is much beyond expected, it is rare to see such work nowadays where people usually do things half way, Osheri delivered a great project with a good eye for the smallest details. I will definitely work more often with him                                    </blockquote>
                            <div></div>
                            <p>
                                danielmarkus91,Israel
                            </p>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

export default Reviews
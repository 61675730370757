import React, { useState, useEffect, useCallback } from "react";
import astronautImage from "./Asteroid.png";
import spaceshipImage from "./space.png";
import "./Home.css";
import planet8 from "./star/planet8.png";
import planet9 from "./star/planet9.png";
import planet1 from "./star/planet1.png";

const SpaceGame = () => {
  const [position, setPosition] = useState({
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  });
  const [backgroundPosition, setBackgroundPosition] = useState(0);
  const [gameState, setGameState] = useState("menu");
  const [asteroids, setAsteroids] = useState([]);
  const [bullets, setBullets] = useState([]);
  const [speedMultiplier, setSpeedMultiplier] = useState(1);
  const [score, setScore] = useState(0);
  const moveStep = 10;
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

  const startGame = () => {
    console.log("Starting game...");
    setGameState("instructions");
  };
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const startActualGame = useCallback(() => {
    console.log("Starting actual game...");
    setGameState("playing");
    setAsteroids([]);
    setBullets([]);
    setSpeedMultiplier(1);
    setScore(0);
    setPosition({ x: window.innerWidth / 2, y: window.innerHeight / 2 });
    window.scrollTo(0, 0);
  }, []);

  const togglePause = useCallback(() => {
    console.log("Toggling pause...");
    setGameState((prevState) => {
      const newState = prevState === "playing" ? "paused" : "playing";
      console.log(`Game state changed from ${prevState} to ${newState}`);
      return newState;
    });
  }, []);

  const exitGame = useCallback(() => {
    console.log("Exiting game...");
    setGameState("menu");
  }, []);

  useEffect(() => {
    const handleScroll = (e) => {
      if (gameState === "playing" || gameState === "paused") {
        e.preventDefault();
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: false });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [gameState]);

  useEffect(() => {
    document.body.style.overflow =
      gameState === "playing" || gameState === "paused" ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [gameState]);

  useEffect(() => {
    if (gameState !== "playing") return;

    const moveBackground = setInterval(() => {
      setBackgroundPosition((prev) => (prev - 1) % window.innerWidth);
    }, 50);

    return () => clearInterval(moveBackground);
  }, [gameState]);

  const updateGameEntities = useCallback(() => {
    if (gameState !== "playing") return;

    setAsteroids((prevAsteroids) =>
      prevAsteroids
        .map((asteroid) => ({
          ...asteroid,
          x: asteroid.x - asteroid.speed * speedMultiplier,
        }))
        .filter((asteroid) => {
          const hitByBullet = bullets.some(
            (bullet) =>
              Math.abs(bullet.x - asteroid.x) < asteroid.size / 2 &&
              Math.abs(bullet.y - asteroid.y) < asteroid.size / 2
          );
          if (hitByBullet) {
            setScore((prevScore) => prevScore + 1);
            return false;
          }
          return asteroid.x + asteroid.size > 0;
        })
    );

    setBullets((prevBullets) =>
      prevBullets
        .map((bullet) => ({ ...bullet, x: bullet.x + 5 }))
        .filter((bullet) => bullet.x < window.innerWidth)
    );

    if (Math.random() < 0.02) {
      const size = Math.random() * (100 - 30) + 30;
      setAsteroids((prevAsteroids) => [
        ...prevAsteroids,
        {
          x: window.innerWidth,
          y: Math.random() * window.innerHeight,
          size: size,
          speed: (Math.random() * (5 - 1) + 1) * speedMultiplier,
        },
      ]);
    }
  }, [bullets, speedMultiplier, gameState]);

  const checkCollision = useCallback(() => {
    if (gameState !== "playing") return;

    const collision = asteroids.some(
      (asteroid) =>
        Math.abs(asteroid.x - position.x) < 100 &&
        Math.abs(asteroid.y - position.y) < 100
    );

    if (collision) {
      setGameState("gameOver");
    }
  }, [asteroids, position, gameState]);

  useEffect(() => {
    if (gameState !== "playing") return;

    const gameLoop = setInterval(() => {
      updateGameEntities();
      checkCollision();
    }, 50);

    const speedIncreaseInterval = setInterval(() => {
      setSpeedMultiplier((prev) => prev + 0.1);
    }, 10000);

    return () => {
      clearInterval(gameLoop);
      clearInterval(speedIncreaseInterval);
    };
  }, [gameState, updateGameEntities, checkCollision]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "p" || event.key === "P") {
        event.preventDefault();
        if (gameState === "playing" || gameState === "paused") {
          togglePause();
        }
      } else if (event.key === "x" || event.key === "X") {
        event.preventDefault();
        if (gameState === "playing" || gameState === "paused") {
          exitGame();
        }
      } else if (gameState === "playing") {
        switch (event.key) {
          case "ArrowUp":
            setPosition((prev) => ({
              ...prev,
              y: Math.max(prev.y - moveStep, 100),
            }));
            break;
          case "ArrowDown":
            setPosition((prev) => ({
              ...prev,
              y: Math.min(prev.y + moveStep, window.innerHeight - 100),
            }));
            break;
          case " ":
            setBullets((prevBullets) => [
              ...prevBullets,
              { x: position.x + 100, y: position.y },
            ]);
            break;
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [gameState, position, togglePause, exitGame]);

  const BackgroundElements = () => (
    <div
      className="shapes"
      style={{
        position: "absolute",
        left: `${backgroundPosition}px`,
        top: 0,
        width: "200%",
        height: "100%",
      }}
    >
      <div className="shape1 shape0" style={{ top: "10%", left: "5%" }} />
      <div className="shape2 shape0" style={{ top: "70%", left: "15%" }} />
      <div className="shape3 shape0" style={{ top: "30%", left: "80%" }} />
      <div className="shape4 shape0" style={{ top: "60%", left: "60%" }} />
      <div className="shape5 shape0" style={{ top: "20%", left: "40%" }} />
      <img
        src={planet1}
        className="planet1 planets"
        alt="Planet 1"
        style={{ top: "5%", left: "10%", width: "80px" }}
      />
      <img
        src={planet8}
        className="planet8 planets"
        alt="Planet 8"
        style={{ top: "70%", left: "75%", width: "120px" }}
      />
      <img
        src={planet9}
        className="planet9 planets"
        alt="Planet 9"
        style={{ top: "40%", left: "30%", width: "100px" }}
      />
      <img
        src="https://i.postimg.cc/s2w4ymZW/human.png"
        className="human"
        alt="Human"
        style={{ top: "50%", left: "50%", width: "150px" }}
      />
      {/* <img
        src="https://i.postimg.cc/h4J6yzHs/planet10.png"
        className="planet10"
        alt="Planet 10"
        style={{ top: "15%", left: "60%", width: "90px" }}
      /> */}
      <img
        src="https://i.postimg.cc/gcf5fcpW/planet11.png"
        className="planet11"
        alt="Planet 11"
        style={{ top: "80%", left: "20%", width: "110px" }}
      />
    </div>
  );

  const FuturisticButton = ({ onClick, children, style }) => (
    <button
      onClick={(e) => {
        e.stopPropagation();
        console.log("Button clicked:", children);
        onClick();
      }}
      style={{
        padding: "15px 30px",
        fontSize: "20px",
        zIndex: 1005,
        color: "#00FFFF",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        border: "2px solid #00FFFF",
        borderRadius: "25px",
        cursor: "pointer",
        outline: "none",
        boxShadow: "0 0 10px #00FFFF, 0 0 20px #00FFFF",
        transition: "all 0.3s ease",
        ...style,
      }}
      onMouseEnter={(e) => {
        e.target.style.backgroundColor = "rgba(0, 255, 255, 0.2)";
        e.target.style.boxShadow = "0 0 15px #00FFFF, 0 0 30px #00FFFF";
      }}
      onMouseLeave={(e) => {
        e.target.style.backgroundColor = "rgba(0, 0, 0, 0.7)";
        e.target.style.boxShadow = "0 0 10px #00FFFF, 0 0 20px #00FFFF";
      }}
    >
      {children}
    </button>
  );

  const renderGameContent = () => {
    switch (gameState) {
      case "menu":
        return (
          <div
            style={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1 style={{ color: "#00FFFF", marginBottom: "20px" }}>
              Space Game
            </h1>
            <FuturisticButton onClick={startGame}>Start Game</FuturisticButton>
          </div>
        );
      case "instructions":
        return (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 1001,
              padding: "30px",
              backgroundColor: "rgba(0,0,0,0.8)",
              borderRadius: "20px",
              textAlign: "center",
              boxShadow: "0 0 20px #00FFFF, 0 0 40px #00FFFF",
              border: "2px solid #00FFFF",
              color: "white",
            }}
          >
            <h2>Instructions</h2>
            <p>Use Up and Down arrow keys to move the spaceship.</p>
            <p>Press Space to shoot bullets.</p>
            <p>Avoid asteroids and shoot them to score points!</p>
            <p>Press P to pause/unpause the game.</p>
            <p>Press X to exit the game at any time.</p>
            <FuturisticButton onClick={startActualGame}>
              Play Game
            </FuturisticButton>
          </div>
        );
      case "playing":
      case "paused":
        return (
          <>
            <div
              style={{
                position: "absolute",
                top: "10px",
                left: "10px",
                fontSize: "24px",
                color: "white",
                zIndex: 1002,
              }}
            >
              Score: {score}
            </div>
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                zIndex: 1005,
                display: "flex",
                gap: "10px",
              }}
            >
              <FuturisticButton
                onClick={togglePause}
                style={{
                  padding: "10px 20px",
                  fontSize: "16px",
                }}
              >
                {gameState === "paused" ? "Resume (P)" : "Pause (P)"}
              </FuturisticButton>
              <FuturisticButton
                onClick={exitGame}
                style={{
                  padding: "10px 20px",
                  fontSize: "16px",
                }}
              >
                Exit (X)
              </FuturisticButton>
            </div>
            <div
              style={{
                position: "absolute",
                left: `${position.x - 100}px`,
                top: `${position.y - 100}px`,
                transition: "all 0.1s",
              }}
            >
              <img
                src={spaceshipImage}
                alt="Spaceship"
                style={{
                  width: "200px",
                  height: "200px",
                  transform: "rotate(90deg)",
                }}
              />
            </div>
            {asteroids.map((asteroid, index) => (
              <div
                key={index}
                style={{
                  position: "absolute",
                  left: `${asteroid.x}px`,
                  top: `${asteroid.y}px`,
                }}
              >
                <img
                  src={astronautImage}
                  alt="Asteroid"
                  style={{
                    width: `${asteroid.size}px`,
                    height: `${asteroid.size}px`,
                  }}
                />
              </div>
            ))}
            {bullets.map((bullet, index) => (
              <div
                key={index}
                style={{
                  position: "absolute",
                  left: `${bullet.x}px`,
                  top: `${bullet.y}px`,
                  width: "20px",
                  height: "6px",
                  backgroundColor: "#FFD700",
                  borderRadius: "3px",
                  boxShadow: "0 0 5px #FFD700, 0 0 10px #FFD700",
                  transform: "rotate(-10deg)",
                }}
              />
            ))}
            {gameState === "paused" && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0,0,0,0.5)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 1004,
                }}
              >
                <div
                  style={{
                    padding: "20px",
                    backgroundColor: "rgba(0,0,0,0.8)",
                    borderRadius: "10px",
                    color: "#00FFFF",
                    fontSize: "36px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  PAUSED
                  <br />
                  <FuturisticButton
                    onClick={togglePause}
                    style={{
                      marginTop: "20px",
                      fontSize: "24px",
                    }}
                  >
                    Resume Game (P)
                  </FuturisticButton>
                  <FuturisticButton
                    onClick={exitGame}
                    style={{
                      marginTop: "20px",
                      fontSize: "24px",
                    }}
                  >
                    Exit Game (X)
                  </FuturisticButton>
                </div>
              </div>
            )}
          </>
        );
      case "gameOver":
        return (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 1001,
              padding: "30px",
              backgroundColor: "rgba(0,0,0,0.9)",
              borderRadius: "20px",
              textAlign: "center",
              boxShadow: "0 0 20px #00FFFF, 0 0 40px #00FFFF",
              border: "2px solid #00FFFF",
              color: "#00FFFF",
              width: "300px",
            }}
          >
            <h1
              style={{
                fontSize: "48px",
                marginBottom: "20px",
                textShadow: "0 0 10px #00FFFF",
              }}
            >
              Game Over!
            </h1>
            <p
              style={{
                fontSize: "24px",
                marginBottom: "30px",
                color: "#FFFFFF",
              }}
            >
              Your Score: {score}
            </p>
            <FuturisticButton
              onClick={startGame}
              style={{
                width: "100%",
                fontSize: "24px",
                borderRadius: "10px",
              }}
            >
              Play Again
            </FuturisticButton>
          </div>
        );
      default:
        return null;
    }
  };
  if (!isDesktop) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          color: "#00FFFF",
          textAlign: "center",
          padding: "20px",
        }}
      ></div>
    );
  }
  return (
    <div
      style={{
        position: "relative",
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <BackgroundElements />
      {renderGameContent()}
    </div>
  );
};

export default SpaceGame;

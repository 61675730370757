import React from "react";

const SpaceMessage = ({ type, message }) => {
  return (
    <div className={`space-message ${type}`}>
      <div className="space-message-content">
        <span className="space-message-icon">
          {type === "success" ? "✓" : "⚠"}
        </span>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default SpaceMessage;

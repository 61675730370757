import logo from "./logo.svg";
import "./App.css";
import Home from "./pages/Home/Home";

import "bootstrap/dist/css/bootstrap.min.css";
import AboutMe from "./pages/AboutMe/AboutMe";
import Skilles from "./pages/skills/Skilles";
import Works from "./components/works/Works";
import MyWork from "./pages/mywork/MyWork";
import "swiper/css/bundle";
import SpaceGame from "./pages/Home/SpaceGame";

function App() {
  return (
    <div className="App">
      {/* <SpaceGame /> */}
      <section id="home" className="page">
        <Home />
      </section>
      <section id="aboutme" className="page">
        <AboutMe />
      </section>
      <section id="skilles" className="page">
        <Skilles />
      </section>
      <section id="work" className="page">
        <MyWork />
      </section>{" "}
      {/*
     
      {/* <section id='works' className='page'>
        <Works />
      </section> */}
    </div>
  );
}

export default App;

import React from "react";
import "./MyWork.css";
import Reviews from "./reviews/Reviews";
import one from "./img/one.jpeg";
import two from "./img/two.png";
import pic3 from "./img/pic3.jpeg";
import logo192 from "./img/logo192.png";
const MyWork = () => {
  return (
    <section className="section_myskills">
      <div className="mywork">
        <div className="pra">
          <h1>My Works</h1>

          <div id="home-magicwall" className="fake-magicwall">
            <ul>
              <li>
                <div className="magic-wall_item lazyload">
                  <img src={one} alt="" />
                  <div className="hover-content"></div>
                  <a
                    href="https://uptimizeconsulting.com/"
                    className="colorbox"
                    target="_blank"
                    rel="noreferrer"
                  ></a>
                </div>
              </li>
              <li>
                <div className="magic-wall_item lazyload">
                  <img src={two} alt="" />
                  <div class="hover-content"></div>
                  <a
                    href="https://kindnessshake.netlify.app/"
                    className="colorbox"
                    target="_blank"
                    rel="noreferrer"
                  ></a>
                </div>
              </li>
              <li
                style={{
                  marginTop: "20px",
                }}
              >
                <div className="magic-wall_item lazyload">
                  <img src={pic3} alt="" />
                  <div class="hover-content"></div>
                  <a
                    href="https://lompini.netlify.app/"
                    className="colorbox"
                    target="_blank"
                    rel="noreferrer"
                  ></a>
                </div>
              </li>
              <li>
                <div className="magic-wall_item lazyload">
                  <img src={logo192} alt="" />
                  <div class="hover-content"></div>
                  <a
                    href="https://thunder.co.il/"
                    className="colorbox"
                    target="_blank"
                    rel="noreferrer"
                  ></a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <Reviews />
      </div>
    </section>
  );
};

export default MyWork;

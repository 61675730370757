import React, { useEffect, useState } from "react";
import "./Resume.css";
import Button from "react-bootstrap/Button";
import { Icon } from "@iconify/react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import ProgressBar from "react-bootstrap/ProgressBar";
import me from "../../data/image/me.jpeg";

const Resume = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [phone, setPhone] = useState(false);
  console.log("====================================");
  console.log(typeof width);
  console.log("====================================");
  useEffect(() => {
    if (width > 800) {
      setPhone(true);

      console.log(window.innerWidth);
    }
  }, [width]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="resume">
        <div className="how_am_i">
          <div className="profile ">
            <img className="imgpro" src={me} />
            <h1>Oshri Shkuri</h1>
            <h3>Full-Stack Developer </h3>
          </div>
          <div className="about_me">
            <h3>About Me</h3>
            <p className="about">
              Dedicated Full Stack Developer .<br /> Critical and analytical
              thinker who excels at managing high-priority technical projects
              and working effectively as part of a high-performing team.
              Creative problem-solver with advanced knowledge of software tools
              and emerging technologies.
            </p>
            {phone && (
              <>
                <h3>contact me</h3>
                <ul className="contact ">
                  <li>
                    <Icon
                      icon="akar-icons:phone"
                      width="20"
                      height="20"
                      rotate={1}
                      hFlip={true}
                      vFlip={true}
                    />
                    +972549174070
                  </li>
                  <li>
                    {" "}
                    <Icon
                      icon="fa6-solid:location-pin"
                      width="20"
                      height="20"
                      rotate={2}
                      hFlip={true}
                      vFlip={true}
                    />
                    Tel Aviv Israel
                  </li>
                  <li>
                    {" "}
                    <Icon
                      icon="dashicons:email"
                      width="20"
                      height="20"
                      rotate={2}
                      hFlip={true}
                      vFlip={true}
                    />{" "}
                    oshri@oshriwd.com
                  </li>
                </ul>

                <div className="icons ">
                  <a
                    style={{ color: "inherit" }}
                    target="_blank"
                    href=" https://www.linkedin.com/in/oshri-shekuri/"
                  >
                    {" "}
                    <Icon
                      icon="ant-design:linkedin-outlined"
                      width="30"
                      height="30"
                      rotate={2}
                      hFlip={true}
                      vFlip={true}
                    />
                  </a>

                  <span style={{ marginLeft: 10 }} />
                  <a
                    style={{ color: "inherit" }}
                    href="https://www.facebook.com/osheri.shekuri"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <Icon
                      icon="ant-design:facebook-filled"
                      width="30"
                      height="30"
                      rotate={2}
                      hFlip={true}
                      vFlip={true}
                    />
                  </a>

                  <span style={{ marginLeft: 10 }} />
                  <a
                    style={{ color: "inherit" }}
                    href="https://api.whatsapp.com/send?phone=+0549174070"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon
                      icon="fontisto:whatsapp"
                      width="30"
                      height="30"
                      rotate={2}
                      hFlip={true}
                      vFlip={true}
                    />
                  </a>

                  <span style={{ marginLeft: 5 }} />
                  <a
                    style={{ color: "inherit" }}
                    href="https://www.fiverr.com/oshri_shkuri?up_rollout=true"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon
                      icon="jam:fiverr-circle"
                      width="35"
                      height="34"
                      rotate={2}
                      hFlip={true}
                      vFlip={true}
                    />
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="func">
          <div className="work">
            <h3 className="experience">
              <Icon icon="zondicons:education" width="30" height="30" />
              <span style={{ marginLeft: 6 }} />
              education
            </h3>
            <div className="experience_list">
              <ul>
                <li>
                  <span>
                    Computer Software Engineering <br />
                  </span>
                  <small>Ariel Technical College</small>
                  <small> 2020 - 2021</small>
                </li>
                <li>
                  <span>Full Stack Developer</span>
                  <small>John Bryce</small>
                  <small> 2020</small>
                </li>
                <li>
                  <span>Amal B</span>
                  <small>High-School</small>
                  <small> 2007 - 2009</small>
                </li>
              </ul>
            </div>
          </div>
          <span className="space"></span>
          <div className="work">
            <h3 className="experience">
              {" "}
              <Icon width="30" height="30" icon="clarity:briefcase-solid" />
              <span style={{ marginLeft: 6 }} />
              experience
            </h3>

            <div className="experience_list">
              <ul>
                <li>
                  <span>Full Stack Developer</span>
                  <small>Fiverr-Self-Employed</small>
                  <small>2021 - Now</small>
                </li>
                <li>
                  <span>Ecommerce Marketing Manager</span>
                  <small>Nayo-Organic</small>
                  <small>2019 - 2021</small>
                </li>
                <li>
                  <span>Security Operations Center </span>
                  <small>MedOne</small>
                  <small>2014- 2019</small>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="skills_place">
          <div className="all_skills">
            <h3 className="skills_header">
              {" "}
              <Icon icon="bx:code" width="30" height="30" />{" "}
              <span style={{ marginLeft: 6 }} />
              skills
            </h3>
            <div className="skills">
              <ul>
                <li>
                  {" "}
                  <span id="skill_title">React</span> <ProgressBar now={90} />
                </li>
              </ul>
              <ul>
                <li>
                  {" "}
                  <span id="skill_title">JavaScript </span>{" "}
                  <ProgressBar now={80} />
                </li>
              </ul>
              <ul>
                <li>
                  {" "}
                  <span id="skill_title"> HTML </span> <ProgressBar now={85} />
                </li>
              </ul>
              <ul>
                <li>
                  {" "}
                  <span id="skill_title">CSS</span> <ProgressBar now={87} />
                </li>
              </ul>
              <ul>
                <li>
                  {" "}
                  <span id="skill_title"> Bootstrap </span>
                  <ProgressBar now={88} />
                </li>
              </ul>
              <ul>
                <li>
                  {" "}
                  <span id="skill_title"> NODEJS </span>{" "}
                  <ProgressBar now={60} />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="about_me_down">
          <ul className="contact ">
            <h3
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Contact me
            </h3>
            <li>
              <Icon
                icon="akar-icons:phone"
                width="20"
                height="20"
                rotate={1}
                hFlip={true}
                vFlip={true}
              />
              +972549174070
            </li>
            <li>
              {" "}
              <Icon
                icon="fa6-solid:location-pin"
                width="20"
                height="20"
                rotate={2}
                hFlip={true}
                vFlip={true}
              />
              Tel Aviv Israel
            </li>
            <li>
              {" "}
              <Icon
                icon="dashicons:email"
                width="20"
                height="20"
                rotate={2}
                hFlip={true}
                vFlip={true}
              />
              Osheri134@gmail.com
            </li>
          </ul>
          <div className="icons ">
            <a
              style={{ color: "inherit" }}
              href=" https://www.linkedin.com/in/oshri-shekuri/"
            >
              {" "}
              <Icon
                icon="ant-design:linkedin-outlined"
                width="30"
                height="30"
                rotate={2}
                hFlip={true}
                vFlip={true}
              />
            </a>

            <span style={{ marginLeft: 10 }} />
            <a
              style={{ color: "inherit" }}
              href="https://www.facebook.com/osheri.shekuri"
            >
              {" "}
              <Icon
                icon="ant-design:facebook-filled"
                width="30"
                height="30"
                rotate={2}
                hFlip={true}
                vFlip={true}
              />
            </a>

            <span style={{ marginLeft: 10 }} />
            <a
              style={{ color: "inherit" }}
              href="https://api.whatsapp.com/send?phone=+0549174070"
            >
              <Icon
                icon="fontisto:whatsapp"
                width="30"
                height="30"
                rotate={2}
                hFlip={true}
                vFlip={true}
              />
            </a>

            <span style={{ marginLeft: 5 }} />
            <a
              style={{ color: "inherit" }}
              href="https://www.fiverr.com/oshri_shkuri?up_rollout=true"
            >
              <Icon
                icon="jam:fiverr-circle"
                width="40"
                height="40"
                rotate={2}
                hFlip={true}
                vFlip={true}
              />
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Resume;

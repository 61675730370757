import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Modal from "react-bootstrap/Modal";
import { Icon } from "@iconify/react";
import "./ContactUs.css";
import SpaceButton from "./SpaceButton";
import SpaceMessage from "./SpaceMessage";

const ContactUs = (props) => {
  const form = useRef();
  const [message, setMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const result = await emailjs.sendForm(
        "service_cru0e97",
        "template_1k7tvgt",
        form.current,
        "RA9CGbF71JiWqW76a"
      );
      console.log(result.text);
      setMessage({
        type: "success",
        text: "Message launched successfully!",
      });
      form.current.reset();
    } catch (error) {
      console.error("EmailJS Error:", error);
      setMessage({
        type: "error",
        text: "Mission failed. Please try again.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="space-contact-modal"
    >
      <Modal.Header closeButton className="space-contact-header">
        <Modal.Title id="contained-modal-title-vcenter">
          <h1>Contact Mission Control</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="space-contact-body">
        <div className="space-contact-content">
          <div className="space-contact-info">
            <h3>Ready for your next mission?</h3>
            <p>
              I'm seeking ambitious projects and innovative collaborations.
              Whether you have a groundbreaking idea or a challenging question,
              don't hesitate to reach out.
            </p>
            <ul>
              <li>
                <Icon icon="akar-icons:phone" /> <span>+972 (0) 549174070</span>
              </li>
              <li>
                <Icon icon="ant-design:mail-outlined" />{" "}
                <span>oshri@oshriwd.com</span>
              </li>
            </ul>
          </div>
          <form
            id="contact_form"
            name="contact_form"
            ref={form}
            onSubmit={sendEmail}
            className="space-contact-form"
          >
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="first_name">First Name</label>
                <input
                  type="text"
                  required
                  maxLength="50"
                  className="form-control"
                  id="first_name"
                  name="first_name"
                />
              </div>
              <div className="form-group">
                <label htmlFor="last_name">Last Name</label>
                <input
                  type="text"
                  required
                  maxLength="50"
                  className="form-control"
                  id="last_name"
                  name="last_name"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="email">Email address</label>
                <input
                  type="email"
                  required
                  maxLength="50"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="name@example.com"
                />
              </div>
              <div className="form-group">
                <label htmlFor="Phone">Phone</label>
                <input
                  type="tel"
                  required
                  maxLength="50"
                  className="form-control"
                  id="Phone"
                  name="Phone"
                  placeholder="Phone"
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                className="form-control"
                id="message"
                name="message"
                rows="5"
              ></textarea>
            </div>
            <div className="form-submit">
              <SpaceButton type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Launching..." : "Launch Message"}
              </SpaceButton>
            </div>
          </form>
        </div>
      </Modal.Body>
      {message && <SpaceMessage type={message.type} message={message.text} />}
    </Modal>
  );
};

export default ContactUs;

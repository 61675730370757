import React, { useState } from "react";
import "./Skilles.css";
import Card from "react-bootstrap/Card";
import Resume from "../../components/resume/Resume";
import Button from "react-bootstrap/Button";
import ArrowSkilles from "../../components/arrow/ArrowSkilles";

const Skilles = () => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <section className="section_myskills">
      <div className="myskills">
        <div className="pra">
          <p id="tag">&lt;h1&gt; </p>
          <h1>Skills & Experience</h1>
          <p id="tag">&lt;/h1&gt; </p>
          <p>
            <p id="tag">&lt;p&gt; </p>
            <p>
              I create successful responsive websites that are fast, easy to
              use, and built with best practices.
              <br /> <br />
              The main area of my expertise is full stack development, HTML,
              CSS, JS, building small and medium web apps, custom plugins,
              features, animations, and coding interactive layouts.
              <br /> <br />
            </p>

            <p>
              {" "}
              In addition, I have knowledge in web design, I designed all the
              sites I built myself Visit my linkedin profile for more details or
              just contact me.
            </p>

            <p id="tag">&lt;/p&gt; </p>
            <br />
            <p id="tag">&lt;button&gt; </p>

            <Button variant="primary" onClick={() => setModalShow(true)}>
              My Resume
            </Button>
            <p id="tag">&lt;/button&gt; </p>
            <Resume show={modalShow} onHide={() => setModalShow(false)} />
          </p>
        </div>

        <div>
          {/* <img src="https://i.postimg.cc/Twsj5P0Y/planet5.png" className="planet5 " /> */}
          <img
            src="https://assets.codepen.io/1538474/astronaut.svg"
            className="astronaut"
          />
        </div>
      </div>
      {/* <div className='arr'>
                <ArrowSkilles />

            </div> */}
    </section>
  );
};

export default Skilles;

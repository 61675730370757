import React from "react";

const SpaceButton = ({ type, onClick, children }) => {
  return (
    <button type={type} onClick={onClick} className="space-button">
      <span className="space-button-inner">{children}</span>
    </button>
  );
};

export default SpaceButton;

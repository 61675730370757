import React, { useEffect, useRef } from "react";
import "./AboutMe.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngular,
  faCss3,
  faGitAlt,
  faHtml5,
  faJsSquare,
  faReact,
} from "@fortawesome/free-brands-svg-icons";
import ArrowAbout from "../../components/arrow/ArrowAbout";

const AboutMe = () => {
  const galaxyRef = useRef(null);

  useEffect(() => {
    const galaxy = galaxyRef.current;
    const starCount = 50;

    for (let i = 0; i < starCount; i++) {
      const star = document.createElement("div");
      star.classList.add("star");
      star.style.width = `${Math.random() * 2 + 1}px`;
      star.style.height = star.style.width;
      star.style.left = `${Math.random() * 100}%`;
      star.style.top = `${Math.random() * 100}%`;
      star.style.animationDuration = `${Math.random() * 2 + 1}s`;
      star.style.animationDelay = `${Math.random() * 2}s`;
      galaxy.appendChild(star);
    }
  }, []);

  return (
    <section className="section_aboutme">
      <div className="aboutme">
        <div className="pra">
          <p id="tag">&lt;h1&gt;</p>
          <h1>About Me</h1>
          <p id="tag">&lt;/h1&gt;</p>
          <p>
            <p id="tag">&lt;p&gt;</p>
            <p>
              I'm a Full Stack Developer from Israel.
              <br />I have a great passion for UI design and creating new and
              interesting things.
            </p>
            <br />
            <p>
              In addition, I am involved in photography, it really connects me
              to Full Stack and the visuality of the Internet sites and
              creativity.
            </p>
            <br />
            <span style={{ color: "#F9F871" }}>Let's do something special</span>
            <p id="tag">&lt;/p&gt;</p>
          </p>
        </div>
        <div className="icon-galaxy" ref={galaxyRef}>
          <div className="icon-orbit">
            <div className="icon">
              <FontAwesomeIcon icon={faAngular} />
            </div>
            <div className="icon">
              <FontAwesomeIcon icon={faHtml5} />
            </div>
            <div className="icon">
              <FontAwesomeIcon icon={faCss3} />
            </div>
            <div className="icon">
              <FontAwesomeIcon icon={faReact} />
            </div>
            <div className="icon">
              <FontAwesomeIcon icon={faJsSquare} />
            </div>
            <div className="icon">
              <FontAwesomeIcon icon={faGitAlt} />
            </div>
          </div>
        </div>
      </div>
      <ArrowAbout />
    </section>
  );
};

export default AboutMe;
